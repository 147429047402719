import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import styles from "../Styles/AboutUs.css";
import aboutUsImage from "../Assets/logoabout.png"; // Ensure the path to your image is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const ContactUsWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const phoneNumber = "9647505398000";

const AboutUs = () => {
  return (
    <section id="about-us" className={styles.aboutUs}>
      <Container>
        <div className="aa">
          <h2 className="h22">About Us</h2>
          <p>Discover our story, mission, and values.</p>
        </div>
        <Row className={styles.content}>
          <Col md={6} className={styles.imageCol}>
            <img
              src={aboutUsImage}
              alt="About Us"
              className={styles.image}
              style={{ width: "100%", height: "90%" }}
            />
          </Col>
          <Col md={6} className={styles.textCol}>
            <Card className={styles.card}>
              <Card.Body>
                <Card.Title>Our Journey</Card.Title>
                <Card.Text>
                  Welcome to our company! We are dedicated to delivering
                  top-notch products and services that exceed your expectations.
                  Our journey started with a simple idea, and through hard work
                  and commitment, we have grown into a trusted name in the
                  industry.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.card}>
              <Card.Body>
                <Card.Title>Our Mission</Card.Title>
                <Card.Text>
                  Our mission is to innovate and lead in our field, consistently
                  providing value to our customers. We strive to create
                  solutions that are not only effective but also sustainable and
                  forward-thinking.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.card}>
              <Card.Body>
                <Card.Title>Our Values</Card.Title>
                <Card.Text>
                  <ul>
                    <li>
                      <strong>Integrity:</strong> We uphold the highest
                      standards of integrity in all of our actions.
                    </li>
                    <li>
                      <strong>Customer Focus:</strong> Our customers are at the
                      heart of everything we do.
                    </li>
                    <li>
                      <strong>Innovation:</strong> We foster a culture of
                      creativity and innovation.
                    </li>
                    <li>
                      <strong>Quality:</strong> We are committed to excellence
                      in everything we undertake.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ContactUsWrapper>
          <a
            href={`https://wa.me/${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-icon"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
        </ContactUsWrapper>
      </Container>
    </section>
  );
};

export default AboutUs;
