import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaFilePdf } from "react-icons/fa"; // Importing an icon for the PDF file
import "../Styles/ManualSection.css";

const ManualSection = () => {
  const handleOpenPDF = () => {
    // Replace 'path/to/your-manual.pdf' with the actual path to your PDF file
    window.open("https://romanqadir.github.io/jsonapi/usermanual.pdf");
  };

  return (
    <section id="manual" className="py-5">
      <Container>
        <Row>
          <Col>
            <Card className="manual-card">
              <Card.Body className="text-center"> {/* Center the content */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <FaFilePdf size={80} color="#d9534f" /> {/* PDF Icon */}
                  <h2 className="mt-3">Epilyum User Manual</h2>
                  <p>Click the button below to view or download the complete user manual.</p>
                  <Button 
                    variant="primary" 
                    onClick={handleOpenPDF}
                    className="mt-3"
                  >
                    <FaFilePdf className="mr-2" /> Open User Manual
                  </Button>
                </motion.div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ManualSection;
