import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import img1 from "../Assets/1.png";
import img2 from "../Assets/2.png";
import img3 from "../Assets/3.png";
import AnimatedCard from "./AnimatedSection";

import img4 from "../Assets/alaa.png"; // Add more images
import img5 from "../Assets/kosar.png";
import img6 from "../Assets/walid.png";
import img7 from "../Assets/mazn.png";
import d5 from "../Assets/d5.png";
import "../Styles/Testimonials.css";  
function Testimonials() {
  return (
    <section>
      <Container id="testimonials" className="my-5">
        <h2
          style={{
            textAlign: "center",
            color: "#483285",
            fontWeight: "bold",
            margin: 20,
          }}
        >
          What Our Doctors Say
        </h2>
        <div className="scrolling-wrapper">
          <div className="scrolling-card">
            <AnimatedCard delay={0.1}>
              <Card>
                <Card.Img variant="top" src={img1} />
                <Card.Body>
                  <Card.Title>Dr.Zryan Xoshnaw</Card.Title>
                  <Card.Text>
                    "Epilyum has revolutionized my practice! The results are
                    incredible, and my patients are thrilled."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.2}>
              <Card>
                <Card.Img variant="top" src={img2} />
                <Card.Body>
                  <Card.Title>Dr.Shilan Barnzjy</Card.Title>
                  <Card.Text>
                    "I highly recommend Epilyum for clinics seeking effective
                    and reliable laser hair removal technology."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.3}>
              <Card>
                <Card.Img variant="top" src={img3} />
                <Card.Body>
                  <Card.Title>Dr.Sherko Wais</Card.Title>
                  <Card.Text>
                    "Outstanding experience! The device is top-notch and the
                    support from Epilyum is excellent."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.4}>
              <Card>
                <Card.Img variant="top" src={img4} />
                <Card.Body>
                  <Card.Title>Dr.Alaa Alsumadi</Card.Title>
                  <Card.Text>
                    "Exceptional performance and reliable results with Epilyum."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.5}>
              <Card>
                <Card.Img variant="top" src={img5} />
                <Card.Body>
                  <Card.Title>Dr.Kosar Salahadin</Card.Title>
                  <Card.Text>
                    "Highly efficient and safe for all skin types. Great
                    experience!"
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img6} />
                <Card.Body>
                  <Card.Title>Dr.Walid Saadi</Card.Title>
                  <Card.Text>
                    "A game-changer in laser hair removal technology. Very
                    satisfied."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={img7} />
                <Card.Body>
                  <Card.Title>Dr.Mazn Dizayi</Card.Title>
                  <Card.Text>
                  "Remarkably effective with minimal discomfort, suitable for all skin tones and types."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
          <div className="scrolling-card">
            <AnimatedCard delay={0.6}>
              <Card>
                <Card.Img variant="top" src={d5} />
                <Card.Body>
                  <Card.Title>Dr.Maha alobaidi</Card.Title>
                  <Card.Text>
                  "Epilyum offers advanced hair removal with safety and precision, ideal for every skin type."
                  </Card.Text>
                </Card.Body>
              </Card>
            </AnimatedCard>
          </div>
        </div>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#151B54"
          fill-opacity="1"
          d="M0,288L48,250.7C96,213,192,139,288,122.7C384,107,480,149,576,181.3C672,213,768,235,864,208C960,181,1056,107,1152,85.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
}

export default Testimonials;
