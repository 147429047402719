import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import AboutUs from "./AboutUs"; // Import the AboutUs component
import Home from "./Home"; // Create a Home component to include HeroSection and Testimonials
import Features from "./Features";
import AnimatedSection from "./AnimatedSection";
import ContactUs from "./ContactUs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function App() {

  return (
    
    <Router>
      
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/about-us"
            element={
              <AnimatedSection>
                <AboutUs />
              </AnimatedSection>
            }
          />
          <Route
            path="/contact-us"
            element={
              <AnimatedSection>
                <ContactUs />
              </AnimatedSection>
            }
          />

          <Route
            path="/Features"
            element={
              <AnimatedSection>
                <Features />
              </AnimatedSection>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
