import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import h from "../Assets/epilogo.png";
import laserepi from "../Assets/aac.png";
import "../Styles/Whatsapp.css"
import "../Styles/Headerstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function HeroSection() {
  const words = ["Safe", "Quick", "Cold", "Economic"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animationState, setAnimationState] = useState("show");

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState("hide");
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setAnimationState("show");
      }, 500); // Duration of hide animation
    }, 2000); // Duration before changing the word
    return () => clearInterval(interval);
  }, [words.length]);
  const phoneNumber = "9647505398000";
  return (
    <div className="hero-section" style={{ backgroundColor: "white" }}>
      <Container>
      <a
       href={`https://wa.me/${phoneNumber}`}// Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
  <FontAwesomeIcon icon={faWhatsapp} style={{width:30,height:30}} />
    </a>
        <Row className="align-items-center">
          <Col lg={6}>
            <h1
              className="display-4 mb-4 "
              style={{ fontWeight: "bold", color: "#151B54" }}
            >
              Epilyum Laser Hair Removal :{" "}
              <span className="word-container">
                {words[currentWordIndex].split("").map((letter, index) => (
                  <span
                    key={index}
                    className={animationState}
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    {letter}
                  </span>
                ))}
              </span>
            </h1>
            <p style={{ color: "black" }}>
              Our advanced laser technology ensures precise and effective hair
              removal, leaving your skin smooth and rejuvenated. With our
              experienced laser equipment, we provide
              personalized treatments tailored to your needs. Say goodbye to
              shaving, waxing, and plucking, and embrace the freedom of smooth,
              hair-free skin with Epilyum.
            </p>
            <a href="#below" style={{color:"white",textDecoration:"none"}}>
            <Button style={{ backgroundColor: "#151B54" }} size="lg" >
            
              Get Started
        
             
            </Button>
            </a>
          </Col>
          <Col lg={6} className="text-lg-right">
            <img
              src={laserepi}
              alt="Epilyum"
              className="img-fluid image-shadow"
              style={{ width: 560, height: 780 }}
            />
          </Col>
        </Row>
      </Container>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path id="below"
          fill="#151B54"
          fill-opacity="1"
          d="M0,288L48,250.7C96,213,192,139,288,122.7C384,107,480,149,576,181.3C672,213,768,235,864,208C960,181,1056,107,1152,85.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
}

export default HeroSection;
