import React from "react";

const MapComponent = () => {
  return (
    <div style={styles.mapContainer}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220.3643368278426!2d44.022518075810176!3d36.182020172429894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4007237b3eb3ca6d%3A0x24383129397088f5!2sJamal%20Haji%20Ali%20Mirayi%20Tower!5e0!3m2!1sen!2siq!4v1719253606708!5m2!1sen!2siq"
        width="100%"
        height="500px"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

const styles = {
  mapContainer: {
    width: "90%",
    height: "500px",
    margin: "20px auto",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    overflow: "hidden",
  },
};

export default MapComponent;
