// Services.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import "../Styles/Service.css";
import { motion } from "framer-motion";
import bsupport from"../Assets/bsupport.png"
const Services = () => {
  const ServiceCard = ({ title, text, imageUrl }) => {
    return (
      <Card className="service-card">
        <Card.Img variant="top" src={imageUrl} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <section id="services" className="py-5">
      <div>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          style={{
            textAlign: "center",
            color: "#483285",
            fontWeight: "bold",
            margin: 20,
          }}
        >
          Our Services
        </motion.h2>
      </div>
      <Container>
        <Row>
          <Col md={4}>
            <ServiceCard
              title="24/7 Service"
              text="We offer round-the-clock support to ensure your practice runs smoothly without any interruptions."
              imageUrl="https://img.freepik.com/premium-photo/3d-24-hours-phone-support-illustration-hotline-customer-service-concept-call-center-trendy-modern-vector-3d-style_839035-1734649.jpg"
            />
          </Col>
          <Col md={4}>
            <ServiceCard
              title="Available Spare Parts"
              text="All necessary spare parts are readily available to minimize downtime and keep your equipment in top condition."
              imageUrl="https://img.freepik.com/premium-photo/motherboard-with-bottom-panel-removed_544662-5920.jpg"
            />
          </Col>
          <Col md={4}>
            <ServiceCard
              title="Client Business Support"
              text="Our comprehensive business support helps you maximize your investment and achieve your business goals."
              imageUrl={bsupport}
            />
          </Col>
        </Row>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#151B54"
          fillOpacity="1"
          d="M0,288L48,250.7C96,213,192,139,288,122.7C384,107,480,149,576,181.3C672,213,768,235,864,208C960,181,1056,107,1152,85.3C1248,64,1344,96,1392,112L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Services;
