import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const phoneNumber = "9647505398000";
  return (
    <footer className="text-white py-5" style={{ backgroundColor: "#151B54",borderRadius:"5px" }}>
      <Container>
        <Row>
          <Col lg={4} md={6}>
            <h5>About Epilyum</h5>
            <p>
              Epilyum's advanced laser technology offers precise hair removal
              for smooth skin. Our expert team uses state-of-the-art equipment
              for personalized treatments. Ditch shaving and waxing—enjoy
              hair-free skin with Epilyum.
            </p>
          </Col>
          <Col lg={4} md={6}>
            <h5>Contact Us</h5>
            <address>
              Address: Erbil - 60m Street - Jamal Haji Ali Tower - 4
              <sup>th</sup> Floor - Room 411 - Next to Par Hospital
              <br />
              Phone: +964 750 539 8000 <br />
              <a href="mailto:info@epilyum.com" style={{ color: "white" }}>
                {" "}
                Email: info@epilyum.com
              </a>
            </address>
          </Col>
          <Col lg={4} md={6}>
            <h5>Social Media</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100094224227961&mibextid=LQQJ4d"
                  style={{ color: "white", textDecorationLine: "none" }}
                >
                  <FontAwesomeIcon icon={faFacebook} /> Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@Epilyumiraq"
                  style={{ color: "white", textDecorationLine: "none" }}
                >
                  <FontAwesomeIcon icon={faYoutube} /> Youtube
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/epilyum.iraq_/?hl=en"
                  style={{ color: "white", textDecorationLine: "none" }}
                >
                  <FontAwesomeIcon icon={faInstagram} /> Instagram
                </a>
              </li>
              <li>
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  style={{ color: "white", textDecorationLine: "none" }}
                >
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
