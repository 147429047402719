import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "../Styles/Headerstyle.css";
import logo from "../Assets/mainlogo.png";
import { NavLink } from "react-router-dom";
function Header() {
  return (
    <Navbar style={{ backgroundColor: "#151B54" }} variant="dark" expand="lg">
      <Container>
      
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap", // Ensure the items wrap on smaller screens
          }}
        >
          <div>
            <img src={logo} width="120" height="120" alt="Epilyum logo" />
          </div>
          <div>
            <h3 style={{ margin: 0, color: "white" }}>Epilyum</h3>
          </div>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/" exact>
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/Features">
              Features
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contact-us">
              Contact Us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about-us">
              About Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;
// // export default Header;
// import React from "react";
// import { Navbar, Nav } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
// import "../Styles/Headerstyle.css";
// import logo from "../Assets/mainlogo.png";
// const Header = () => {
//   return (
//     <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
//       <Navbar.Toggle aria-controls="basic-navbar-nav" />
//       <Navbar.Collapse id="basic-navbar-nav">
//         <Nav className="me-auto">
//           <Nav.Link as={NavLink} to="/" exact>
//             Home
//           </Nav.Link>
//           <Nav.Link as={NavLink} to="/features">
//             Features
//           </Nav.Link>
//           <Nav.Link href="#testimonials">Testimonials</Nav.Link>
//           <Nav.Link as={NavLink} to="/about-us">
//             About Us
//           </Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default Header;
