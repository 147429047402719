// Home.js
import React from "react";
import HeroSection from "./HeroSection";
import Testimonials from "./Testimonials";
import Services from "./Services";
import ManualSection from "./ManualSection";
import AnimatedSection from "./AnimatedSection";
import AnimatedService from "./AnimatedService";
import VideoSection from "./VideoSections";

const Home = () => (
  <div>
    <HeroSection />
    <AnimatedSection>
      <Services />
    </AnimatedSection>

    <AnimatedSection>
      <ManualSection />
    </AnimatedSection>
    <AnimatedSection>
      <Testimonials />
    </AnimatedSection>
    <AnimatedSection>
      <VideoSection />
    </AnimatedSection>
  </div>
);

export default Home;
