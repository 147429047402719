import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import MapComponent from "./MapComponent";
import "../Styles/Whatsapp.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
const ContactUsWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  padding: 20px;
`;
const phoneNumber = "9647505398000";
const ContactUs = () => {
  return (
    <>
      <a
       href={`https://wa.me/${phoneNumber}`}// Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
  <FontAwesomeIcon icon={faWhatsapp} style={{width:30,height:30}} />
    </a>
      <ContactUsWrapper>
        {" "}
        <div style={{ width: "100%" }}>
          <ContactForm />
        </div>
      </ContactUsWrapper>
      <ContactUsWrapper>
        <div style={{ width: "100%" }}>
          <MapComponent />
        </div>
      </ContactUsWrapper>
    </>
  );
};

export default ContactUs;
