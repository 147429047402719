
import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";

const VideoCard = ({ title, videoId, description }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Card style={{ marginBottom: "20px" }}>
      <Card.Img
        variant="top"
        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button style={{backgroundColor:"#151B54",borderColor:"transparent"}} href={embedUrl}>
          Watch Video
        </Button>
      </Card.Body>
    </Card>
  );
};

const Featurevideos = () => {
  const videos = [
    {
      title: "Rectangle Spot Size",
      videoId: "TjnvlVLJPcs",
      description: "Epilyum's rectangle spot size laser hair removal ensures faster, even treatments by covering larger areas efficiently with consistent energy distribution.",
    },
    {
      title: "Economic",
      videoId: "6gI9srKytUk",
      description: "Epilyum stands out with its lower servicing needs, providing businesses with more economical choice compared other laser brands",
    },
    {
      title: "3 Wavelength",
      videoId: "-3Vu7GDx0Rw",
      description: "Epilyum's 3 wavelength laser combines 755 nm, 808 nm, and 1064 nm for advanced, versatile hair removal techniques.",
    },
    {
      title: "More Speed , Less Time",
      videoId: "h7toZDcgHMY",
      description: "The rapid performance of epilyum allows for convenient and time-saving hair removal sessions",
    },
    {
      title: "Powerful Laser",
      videoId: "0-3fTPdhjpc",
      description: "Epilyum boasts a powerful power supply for optimum performance , Harness the best power for hair removal",
    },
    {
      title: "comfortable System for user",
      videoId: "N-dc6LWRuTI",
      description: "We priotize the comfort of our nursing professions with our hair removal laser, featuring ergonomic grips and intutive controls for ease of use",
    },
   
    // Add more videos as needed
  ];

  return (
    <Container>
      <h2 className="my-4 text-center" style={{ color: "#483285" }}>
        {" "}
     Main Features of Our Laser
      </h2>
      <Row>
        {videos.map((video, index) => (
          <Col key={index} sm={12} md={6} lg={4}>
            <VideoCard
              title={video.title}
              videoId={video.videoId}
              description={video.description}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Featurevideos;
