import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";

const VideoCard = ({ title, videoId, description }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Card style={{ marginBottom: "20px" }}>
      <Card.Img
        variant="top"
        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button style={{backgroundColor:"#151B54",borderColor:"transparent"}} href={embedUrl}>
          Watch Video
        </Button>
      </Card.Body>
    </Card>
  );
};

const VideoSection = () => {
  const videos = [
    {
      title: "Introduction to Laser Technology",
      videoId: "O_qBc0cB2Tk",
      description: "Learn the basics of laser technology and its applications.",
    },
    {
      title: "How to Use Laser Equipment Safely",
      videoId: "QQAWi0Rr2jY",
      description: "Safety tips and guidelines for using laser equipment.",
    },
    {
      title: "Advanced Laser Techniques",
      videoId: "uzG_9YS8_Vs",
      description: "Explore advanced techniques in laser usage.",
    },
    // Add more videos as needed
  ];

  return (
    <Container>
      <h2 className="my-4 text-center" style={{ color: "#483285" }}>
        {" "}
        Learn about the latest advancements in laser technology and its
        applications in various industries.
      </h2>
      <Row>
        {videos.map((video, index) => (
          <Col key={index} sm={12} md={6} lg={4}>
            <VideoCard
              title={video.title}
              videoId={video.videoId}
              description={video.description}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default VideoSection;
