import React, { useState } from "react";
import emailjs from "emailjs-com";
import img from "../Assets/mainlogo.png";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    alert("message sent");
    e.preventDefault();

    emailjs
      .send(
        "service_xb9r30c", // Your service ID
        "template_8nv3omd", // Your template ID
        formData,
        "xKNMmzxPU3tqOBjLg" // Your user ID (public key)
      )
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
      });
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h1 style={styles.heading}>Contact Us</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="name" style={styles.label}>
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="email" style={styles.label}>
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="message" style={styles.label}>
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              style={styles.textarea}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            Send Message
          </button>
        </form>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={
            "https://img.freepik.com/premium-vector/marketing-research-isolated-cartoon-vector-illustrations_107173-30314.jpg?w=1060"
          }
          alt="Illustration"
          style={styles.image}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: "500px",
    margin: "0 auto",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  formContainer: {
    width: "50%",
    padding: "20px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #483285",
    fontSize: "14px",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #483285",
    fontSize: "14px",
    resize: "none",
    height: "100px",
  },
  button: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#483285",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  imageContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  image: {
    maxWidth: "90%",
  height:"400px",
    borderRadius: "10px",
  },
};

export default ContactForm;
